<template>
    <div class="page-content">
        <div class="park-form">
            <el-form inline label-position="top" ref="searchForms" style="display: flex;flex-wrap: wrap">
                <el-form-item label="主题名称" prop="username">
                    <el-input v-model="searchForm.name" style="width: 220px;" clearable
                              placeholder="请填主题名称"></el-input>
                </el-form-item>

                <el-form-item style="margin-top: auto;margin-right: auto;">
                    <div class="fill-btn" @click="themeList">查询</div>
                </el-form-item>
                <el-form-item style="margin-top: auto;margin-left: 20px;margin-right: 0;">
                    <div class="fill-btn" @click="handShowAdd">新增</div>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-content">
            <div class="table-top">
                <div class="table-top-text">
                    <!--已选择{{selectData.length}}项-->
                </div>
            </div>
            <el-table :data="tableData" stripe
                      v-loading="loading"
                      @selection-change="handleSelectionChange"
                      cell-class-name="text-center"
                      header-cell-class-name="header-row-class-name">
                <el-table-column prop="id" label="ID" width="66px"></el-table-column>
                <el-table-column prop="title" label="主题名称" min-width="120px"></el-table-column>
                <el-table-column prop="level" label="难度等级" width="80"></el-table-column>
                <el-table-column prop="price" label="单价" min-width="100px"></el-table-column>
                <el-table-column prop="number" label="建议人数" width="80"></el-table-column>
                <el-table-column prop="length" label="时长" width="120px"></el-table-column>
                <el-table-column prop="images" label="主题封面" width="80">
                    <!-- 图片的显示 -->
                    <template slot-scope="{row}">
						<el-image style="width: 40px; height: 40px" :src="row.images" :preview-src-list="[row.images]">
						</el-image>
                    </template>
                </el-table-column>

                <el-table-column prop="created_at" width="148px" label="创建时间"></el-table-column>
                <el-table-column width="120" fixed="right">
                    <template slot-scope="{row}">
                        <div class="btn-content">
                            <el-button class="el-icon-edit-outline cur-p" type="primary" size="mini"
                                       @click="handEditShow(row)"></el-button>
                            <el-button class="el-icon-delete cur-p" type="primary" size="mini"
                                       @click="teacherDel(row)"></el-button>

                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-content">
                <el-pagination
                        background
                        :current-page.sync="searchForm.page"
                        :page-size="searchForm.limit"
                        @current-change="searchForm.page=$event"
                        @size-change="handleSizeChange"
                        layout="total, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="主题创建" :visible.sync="showAdd">
            <div style="padding: 20px;">
                <el-form ref="form" :model="addCourse" label-width="80px">
                    <el-form-item label="标题">
                        <el-input v-model="addCourse.title"></el-input>
                    </el-form-item>

                    <el-form-item label="活动封面">
                        <el-upload
                                class="avatar-uploader"
                                action="/admin/upload"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="addCourse.images" :src="addCourse.images" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <div class="el-upload__tip" slot="tip">351*250</div>
                        </el-upload>
                    </el-form-item>

                    <el-form-item label="难度等级">
                        <el-input v-model="addCourse.level"></el-input>
                    </el-form-item>
                    <el-form-item label="单价">
                        <el-input v-model="addCourse.price"></el-input>
                    </el-form-item>
                    <el-form-item label="建议人数">
                        <el-input v-model="addCourse.number"></el-input>
                    </el-form-item>
                    <el-form-item label="主题标签">
                        <el-input v-model="addCourse.icon"></el-input>
                    </el-form-item>
                    <el-form-item label="游玩须知">
                        <RichText v-model="addCourse.notic"></RichText>
                    </el-form-item>
                    <el-form-item label="游戏时长">
                        <el-input v-model="addCourse.length"></el-input>
                    </el-form-item>
                    <el-form-item label="复盘视频">
                        <el-upload
                                class="avatar-uploader"
                                action="/admin/uploadVideo"
                                :show-file-list="false"
								:data="{is_editor:2}"
                                :on-success="handleMP4Success">
                            <video  controls   v-if="addCourse.video" :src="addCourse.video" class="avatar"></video>
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>

                        </el-upload>
                        <el-button v-if="addCourse.video" @click="addCourse.video=''">删除视频</el-button>
                    </el-form-item>
                    <el-form-item label="主题描述">
                        <RichText v-model="addCourse.desc"></RichText>

                    </el-form-item>
                    <el-form-item label="复盘信息">
                        <RichText v-model="addCourse.analyse" refName="myQuillEditor"></RichText>
                    </el-form-item>


                    <el-form-item>
                        <el-button type="primary" :loading="editing" @click="createCourse" style="margin: 10px auto">保存</el-button>

                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="主题编辑" :visible.sync="showEdit">
            <div style="padding: 20px;">
                <el-form ref="form" :model="editForm" label-width="80px">
                    <el-form-item label="标题">
                        <el-input v-model="editForm.title"></el-input>
                    </el-form-item>

                    <el-form-item label="活动封面">
                        <el-upload
                                class="avatar-uploader"
                                action="/admin/upload"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="editForm.images" :src="editForm.images" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            <div class="el-upload__tip" slot="tip">351*250</div>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="难度等级">
                        <el-input v-model="editForm.level"></el-input>
                    </el-form-item>
                    <el-form-item label="单价">
                        <el-input v-model="editForm.price"></el-input>
                    </el-form-item>
                    <el-form-item label="建议人数">
                        <el-input v-model="editForm.number"></el-input>
                    </el-form-item>
                    <el-form-item label="主题标签">
                        <el-input v-model="editForm.icon"></el-input>
                    </el-form-item>
                    <el-form-item label="游玩须知">
                        <RichText v-model="editForm.notic"></RichText>
                    </el-form-item>
                    <el-form-item label="游戏时长">
                        <el-input v-model="editForm.length"></el-input>
                    </el-form-item>
                    <el-form-item label="复盘视频">
                        <el-upload
                                class="avatar-uploader"
                                action="/admin/uploadVideo"
                                :show-file-list="false"
                                :on-success="handleMP4sSuccess">
                            <video controls v-if="editForm.video"  :src="editForm.video" class="avatar"></video>
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <el-button v-if="editForm.video" @click="editForm.video=''">删除视频</el-button>
                    </el-form-item>
                    <el-form-item label="主题描述">
                        <RichText v-model="editForm.desc"></RichText>
                    </el-form-item>
                    <el-form-item label="复盘信息">
                        <RichText v-model="editForm.analyse" refName="myQuillEditor"  style="height: 400px"></RichText>
                    </el-form-item>
                    <!--<el-form-item label="主题回顾">-->
                        <!--<RichText v-model="editForm.content" :type="1" refName="myQuillEditor"  style="height: 400px"></RichText>-->
                    <!--</el-form-item>-->

                    <el-form-item>
                        <el-button :loading="editing" type="primary" @click="saveCourse" style="margin: 10px auto">保存</el-button>

                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <!--<el-dialog title="视频上传" :visible="upDialog" width="50%" :before-close="closeMy" v-loading="loadingUp">-->
            <!--<el-upload-->
                    <!--class="upload-demo"-->
                    <!--action="/admin/uploadVideo"-->
                    <!--ref="uploadVideo"-->
                    <!--:before-upload="beforeUp"-->
                    <!--:show-file-list="false"-->
                    <!--:on-success="(response, file, fileList)=>handleSuccess(response, file, fileList)"-->
                    <!--:file-list="fileList">-->
                <!--<el-button size="small" type="primary">点击上传</el-button>-->
                <!--&lt;!&ndash;      <img :src="row.url"  class="avatar" alt="">&ndash;&gt;-->
            <!--</el-upload>-->
        <!--</el-dialog>-->
    </div>
</template>

<script>
    import {delToast, formVerify} from './../../utlis/decorators'
    import RichText from './../../components/RichText'

    export default {
        name: "tenant_account",
        props: {
            parkId: {
                type: [Number, String],
                default: null
            },
        },
        components: {
            RichText
        },
        data() {
            return {
                upDialog:false,
                dialogFormVisible: false,
                loading: false,
                currentTop: 0,
                total: 0,
                showAdd: false,
                showEdit: false,
                showEditPsd: false,
                editing: false,
                searchForm: {
                    page: 1,
                    limit: 10,
                    username: ''
                },
                form: {
                    nickname: '',
                    images:''
                },
                editForm: {
                    role_ids: [],
                    nickname: '',
                    username: '',
                    password: '',
                    status: '',
                    images:''
                },
                currentId: '',
                tableData: [],
                typeList: [],
                addCourse: {
                    title: '',
                    level: '',
                    price: '',
                    number: '',
                    icon: '',
                    notic: '',
                    length: '',
                    images:'',
                    video:'',
                    desc:'',
                    analyse:''
                },
                teacherAll: [],
                fileList:[],
            }
        },
        created() {
            this.themeList();
            this.$event.$on('upVideo',(data)=>{
                if(data == 2){
                    return
                }
                this.upDialog=true
            })
        },
        computed: {

            getRules() {
                return {
                    role_ids: [{required: true, message: `角色不能为空`, trigger: ['blur', 'change']}],
                    nickname: [{required: true, message: `昵称不能为空`, trigger: ['blur', 'change']}],

                }
            }
        },
        watch: {
            'searchForm.page'() {
                this.themeList();
            },
        },
        methods: {
            //头像
            handleAvatarSuccess(res, file) {

                console.log(res);;
                if(res.code==400){

                    this.$message.error(res.msg)
                }else {
                    this.editForm.images = res.url;
                    this.addCourse.images = res.url;
                    this.$forceUpdate();
                }

            },
            //视频
            handleMP4Success(res, file) {

                console.log(res);;
                if(res.code==400){

                    this.$message.error(res.msg)
                }else {

                    this.addCourse.video = res.url;
                    this.$forceUpdate();
                }

            },
            //视频
            handleMP4Success1(res, file) {

                console.log(res);;
                if(res.code==400){

                    this.$message.error(res.msg)
                }else {

                    this.addCourse.video_h = res.url;
                    this.$forceUpdate();
                }

            },
            handleMP4sSuccess(res, file) {

                this.editForm.video = res.url;
                this.$forceUpdate();


            },

            //关闭
            closeMy(){
                this.upDialog=false
            },

            handShowAdd() {
                try {
                	this.showAdd = true;
					for (let i in this.addCourse) {
						this.addCourse[i] = '';
					}
                	this.$refs['form'].resetFields()
                	
                } catch {}
            },
            handEditShow(row) {
                let roles = [];
                // role.forEach((item, index) => {
                // 	roles.push(item.role_id)
                // });
                //
                // this.editForm.role_ids = roles;
                this.editForm = row;
                // this.editForm.account = account;
                // // this.editForm.status = status.toString();
                // this.currentId = id;
                this.showEdit = true;
            },
            //页面数量改变
            handleSizeChange(val) {
                this.searchForm.page = 1;
                this.searchForm.limit = val;
            },
            handleSelectionChange(val) {
                this.selectData = val;
            },
            //获取列表
            async themeList() {
                let [e, data] = await this.$api.themeList(this.searchForm);
                if (e) return;
                console.log(data);
                if (data.code === 200) {
                    this.tableData = data.data.data;
                    this.total = data.data.total;
                }
            },
            //上传视频成功
            handleSuccess(response, file, fileList){
                // console.log(file.response.url);
                if(file.status == "success"){
                    this.$event.$emit('setVideo',file.response.url)
                    this.upDialog=false
                    // this.$message.success('上传成功！')
                }else {
                    this.$message.error('上传失败，请重试！')
                }


            },
            //导师列表
            async getTeacherAll(query) {
                let [e, data] = await this.$api.getTeacherListAll({name: query});
                if (e) return;
                console.log(data);
                if (data.code === 200) {
                    this.teacherAll = data.data;
                }

            },
            @delToast()
            async teacherDel({id}) {
                let [e, data] = await this.$api.delTheme({id});
                if (e) return;
                if (data.code === 200) {
                    this.themeList();
                    this.$message.success('删除成功')
                } else {
                    this.$message.warning(`${data.msg}`)
                }
            },
			

            //添加主题
            async createCourse() {
                this.editing = true;
                let [e, data] = await this.$api.createTheme(this.addCourse);
                if (e){this.editing = false;return} ;
                if (data.code === 200) {
                    this.themeList();
                    this.showAdd = false;

                    this.$message.success('添加成功')
					this.editing = false;
                } else {
                    this.$message.warning(`${data.msg}`)
					this.editing = false;
                }
            },
            //账号编辑

            async saveCourse() {
                this.editing = true;
                let [e, data] = await this.$api.saveTheme(this.editForm);
                
                if (e){this.editing = false; return};
                if (data.code === 200) {
                    this.showEdit = false;
                    this.themeList();
                    this.$message.success('保存成功')
					this.editing = false;
                } else {
                    this.$message.warning(`${data.msg}`)
					this.editing = false;
                }
            }
        },
    }
</script>
<style scoped lang="less">
    .page-content {
        width: calc(~'100% - 60px');
        margin: 26px 30px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 4px;
        padding: 20px 30px;

        .park-form {
            border-bottom: 1px solid #efefef;
        }

        .table-content {
            padding-top: 20px;

            .table-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .table-top-text {
                    font-size: 16px;
                    font-weight: 400;
                    color: rgba(35, 39, 52, 1);
                }

                .table-top-btn {
                }
            }
        }
    }

    .btn-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .iconfont {
            font-size: 20px;
            color: rgb(190, 199, 213);
            margin: 0 10px;
            cursor: pointer;
        }

        .icon-shanchu {
            font-size: 24px;
        }
    }

    .box-shadow {
        width: calc(~'100% + 40px');
        height: 50px;
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        box-shadow: 0 0 4px #b4b4b4;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
</style>
